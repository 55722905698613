import "./scss/main.scss";
import App from './App.svelte';

const app = new App({
	target: document.body,
	props: {
	}
});

export default app;
