<style>

    .buzzer {
        margin-top: 2rem;
    }


    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

</style>

<script>
    import axios from 'axios';
    import {HsvPicker} from 'svelte-color-picker';


    let navGroup = "groups";

    $ : {
        if(navGroup === "lamps") {
            loadLamps();
        }
        if(navGroup === "groups") {
            loadGroups();
        }


    }

    let lamps;
    let groups;
    let selectedLamp;
    let selectedGroup;

    let rgb;
    let rgbGroup;
    var host = "http://192.168.0.160:3000"

    function loadLamps() {
        axios.get(host + "/getLamps").then((req) => {
            console.log(req.data);
            lamps = req.data;
        })
    }

    function loadGroups() {
        axios.get(host + "/getGroups").then((req) => {
            console.log(req.data);
            groups = req.data;
        })
    }

    function startBuzz() {
        let colorObj = (isColorLamp() && rgb) ? {color: rgb} : {}
        axios.post(host + "/buzz", Object.assign({}, {
            id: selectedLamp.attributes.attributes.id,
            brightness: selectedLamp.state.attributes.bri
        }, colorObj), (res) => {
            console.debug(res);
        });
    }

    function startBuzzGroup() {
        let colorObj = (isColorGroup() && rgbGroup) ? {color: rgbGroup} : {}
        axios.post(host + "/buzzGroup", Object.assign({}, {
            id: selectedGroup.attributes.attributes.id,
            brightness: selectedGroup.action.attributes.bri
        }, colorObj), (res) => {
            console.debug(res);
        });
    }

    function colorCallback(rgba) {

        rgb = rgba.detail.r + "," + rgba.detail.g + "," + rgba.detail.b;
    }

    function colorCallbackGroup(rgba) {

        rgbGroup = rgba.detail.r + "," + rgba.detail.g + "," + rgba.detail.b;
    }

    function switchOnOff() {
        axios.post(host + "/onOff", {
            id: selectedLamp.attributes.attributes.id,
            on: selectedLamp.state.attributes.on
        }, (res) => {
            console.debug(res);
        });
    }

    function switchOnOffGroup() {
        axios.post(host + "/onOffGroup", {
            id: selectedGroup.attributes.attributes.id,
            on: selectedGroup.state.attributes.any_on
        }, (res) => {
            console.debug(res);
        });
    }

    function isColorLamp() {
        return selectedLamp.attributes.attributes.type.includes("color")
    }

    function isColorGroup() {
        return !!selectedGroup.action.attributes.hue
    }


</script>

<nav class="navbar navbar-light bg-light mb-3">
    <a class="navbar-brand" href="#">
        <img src="/img/hue.png" width="30" height="30" alt="">
    </a>

    <ul class="nav nav-pills mr-auto">

        <li class="nav-item mr-2">
            <button class="nav-link {(navGroup === 'groups' ? 'active' : '')}" type="button"
                    on:click={() => navGroup = 'groups'}>
                Gruppen
            </button>
        </li>
        <li class="nav-item">
            <button class="nav-link {(navGroup === 'lamps' ? 'active' : '')}" type="button"
                    on:click={() => navGroup = 'lamps'}>Lampen
            </button>
        </li>
    </ul>

</nav>

<main class="container">

    {#if navGroup === 'lamps'}
        <div class="form-row">
            {#if lamps}
                <label class="col-form-label">Lampe</label>
                <select class="form-control" bind:value={selectedLamp} on:change={() => {

        }}>
                    {#each lamps as lamp}
                        <option value="{lamp}">{lamp.attributes.attributes.name}</option>
                    {/each}
                </select>
            {/if}
        </div>

        {#if selectedLamp && isColorLamp()}
            <label>Farbe</label>
            <HsvPicker on:colorChange={colorCallback}/>
            <hr>

        {/if}
        {#if selectedLamp}
            <label>Helligkeit</label>
            <input type="range" min="0" max="254" bind:value={selectedLamp.state.attributes.bri}/><br/>
            {selectedLamp.state.attributes.bri}

            <p style="margin-top: 20px"></p>
            <label style="margin-bottom: 5px">An/Aus</label>
            <label class="switch">
                <input type="checkbox" bind:checked={selectedLamp.state.attributes.on}
                       on:change={() => { switchOnOff(); }}>
                <span class="slider round"></span>
            </label>
        {/if}


        {#if selectedLamp}
            <br/>
            <button class="buzzer btn btn-primary" on:click={startBuzz}>Speichern</button>
        {/if}
    {/if}

    {#if navGroup === 'groups' && groups}
       <div class="form-row">
           <label class="col-form-label">Gruppe</label>
           <select class="form-control" bind:value={selectedGroup}>
               {#each groups as group}
                   <option value={group}>{group.attributes.attributes.name} </option>
                   {/each}
           </select>
       </div>

        {#if selectedGroup && isColorGroup()}
            <label>Farbe</label>
            <HsvPicker on:colorChange={colorCallbackGroup}/>
            <hr>

        {/if}

        {#if selectedGroup}
            <label>Helligkeit</label>
            <input type="range" min="0" max="254" bind:value={selectedGroup.action.attributes.bri}/><br/>
            {selectedGroup.action.attributes.bri}

            <p style="margin-top: 20px"></p>
            <label style="margin-bottom: 5px">An/Aus</label>
            <label class="switch">
                <input type="checkbox" bind:checked={selectedGroup.state.attributes.any_on}
                       on:change={() => { switchOnOffGroup(); }}>
                <span class="slider round"></span>
            </label>
        {/if}

        {#if selectedGroup}
            <br/>
            <button class="buzzer btn btn-primary" on:click={startBuzzGroup}>Speichern</button>
        {/if}
    {/if}
</main>

